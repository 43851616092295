.admin-product-key-page{
    // flex: 4;
    // background-color: #f7f7f7;
    height: 100vh;
    
    .admin-product-key-page-data{
        padding-top: 36px;
        font-family: 'Montserrat', sans-serif;
        // font-weight: bold;

        .admin-product-key-page-title {
            // border-bottom: 1px solid #d5d5d5;
            padding: 0px 40px 8px 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
        .admin-product-key{
            padding: 10px 20px 8px 20px;
            
            .ant-table-admin {
                background-color: #fff;
                box-shadow: 0 10px 30px 0 rgb(182 182 182 / 27%);
                // box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
                padding: 1rem;
                border-radius: 10px;
                &:hover{
                    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
                }

                .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
                .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
                .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
                .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
                    background: unset; //Change the existing color to `unset`
                }

                .ant-table-thead > tr > th {
                    background: #fff !important;
                    font-weight: 700 !important;
                }

            }
        }
    }
}



.subFormButton{
    background-color: #0d3d84;
}

.import_keys_btn {
    background-color: #4BB543;
    border: 1px solid #4BB543;
    color: #fff;
    padding: 0.3rem 1rem;
    font-size: 15px !important;
    &:hover{
        background-color: #ffffff;
        color: #30822a;
        border: 1px solid #4BB543;
    }
    & svg {
        margin-bottom: 0.3rem;
    }
}