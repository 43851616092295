

 

  .cartTotal {
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.15rem 0.375rem 0.0625rem, rgb(0 0 0 / 6%) 0rem 0.25rem 0.25rem -0.0625rem;
    padding: 1.4rem;
    border-radius: 10px;
  }

  .cart-page-btn {
    padding: 0.4rem 1.6rem !important;
    border: none;
    &:hover{
        background-color: #fff;
        color: rgb(21, 21, 21);
        box-shadow: 2px 2px 10px #dbdbdb;
    }
  }
  .cart-page-checkout-btn {
    background-color: #ff4d4f;
    padding: 0.6rem 2rem !important;
    border: none;
    &:hover{
        background-color: #fff;
        color: rgb(21, 21, 21);
        box-shadow: 2px 2px 10px #dbdbdb;
    }
}


  // for table
  .ant-table-cart-page {
  //  background-color: #fff;
   // box-shadow: 0 10px 30px 0 rgb(0 60 179 / 5%);
  //  box-shadow: rgb(0 0 0 / 10%) 0rem 0.15rem 0.375rem 0.0625rem, rgb(0 0 0 / 6%) 0rem 0.25rem 0.25rem -0.0625rem;
   padding: 1rem;
  //  border-radius: 10px;

   .ant-table-pagination.ant-pagination {
        display: none;
    }

   .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
   .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
   .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
   .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
       background: unset; //Change the existing color to `unset`
   }

   .ant-table-thead > tr > th {
       background: #fff !important;
   }
  //  .ant-table-cell{
  //   text-align: left !important;
  //  }

}


.cartdata-Subtotal-table {
  border: none;
  text-align: center;
  &:hover {
    border: none;
  }
}
  
// increment derement
.IncrDecrStyle{
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 2px;
  height: 100%;
  width: 100%;
  display: block;
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }

}

.delete-cartItem {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 26px;
}


.ant-table {
  display: block;

  @media screen and (max-width: 668px) {
    &-thead {
      display: none;
    }

    &-thead>tr,
    &-tbody>tr {

      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }

      >th,
      >td {
        display: block;
        width: 160px !important;
        border: none;
        padding: 0 1rem;
        font-size: 1.1rem;

        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}