.carousel-image {
    vertical-align: middle;
    width: 100% !important;
    height: auto !important; 
  }
  
  .carousel-item {
    width: 100% !important;
    height: 600px !important; 
  }
  
  .fade:not(.show) {
    opacity: 1 !important;
  }
  
  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 0.4s !important;
    animation-name: fade;
    animation-duration: 0.4s !important;
  }
  
  @-webkit-keyframes fade {
    from {opacity: unset !important;} 
    to {opacity: unset !important;}
  }
  
  @keyframes fade {
    from {opacity: unset !important;} 
    to {opacity: unset !important;}
  }
  
  .pause {
    -webkit-animation-name: pause;
    -webkit-animation-duration: 6s !important;
    animation-name: none !important;
    animation-duration: 6s !important;
  }
  
  @keyframes pause {
    0% {opacity: 1 !important;}
    90% {opacity: 1 !important;}
    
  }
  @-webkit-keyframes pause {
    0% {opacity: 1 !important;}
    90% {opacity: 1 !important;}
    
  }
  
  .active, .dot:hover {
    background-color: #ffffff !important;
  }


  /* Product main page css */

  .goNumber {
    width: 13% !important;
    padding: 14px 16px !important;
  }

  .warranty {
    background-color: #fff;
    border: 2px solid #ff9e10;
    border-radius: 4px;
    padding: 48px;
  }

  @media only screen and (max-width: 668px){
    .warranty{
      margin-top: 40px;
    }
    .product-page {
      padding: 28px !important;
    }
  } 

  .IncrDecrStyle{
    background-color: #fff;
    // border: 1px solid #ced4da;
    border: 0px !important;
    border-radius: 2px;
    height: 38px;
    text-align: center;
    &:hover{
      border: 0px !important;
    }
  }

  .buy-now {
    border: 1px solid #c0c0c0;
    border-radius: 8px;
    padding: 14px;
  }

  .product-page-proqty{
    border: 1px solid rgb(213, 213, 213);
    box-shadow: rgb(229 229 229) 1px 1px 8px;
    float: left;
    margin-right: 12px;
    height: 54px;
    align-items: center;
  }
  
  .exclusive-features {
    background-color: #fff;
    box-shadow: 2px 2px 30px #d1d1d1;
    padding: 1rem;
    border-radius: 26px;
    & li{
      font-size: 1rem;
      color: #212121;
      font-family: 'Ubuntu', sans-serif;
    }
}

.product_page_description{
  background-color: #fff;
  color: #212121;
  & h6{
    color: #0d6efd;
    font-weight: bold;
    margin-top: 2rem;
  }
  & h5{
    color: #0d6efd;
    font-weight: bold;
    margin-top: 2rem;
  }
  & h4{
    color: #0d6efd;
    font-weight: bold;
    margin-top: 2rem;
  }
  & h3{
    color: #0d6efd;
    font-weight: bold;
    margin-top: 2rem;
  }
  & h2{
    color: #0d6efd;
    font-weight: bold;
    margin-top: 2rem;
  }
  & h1{
    color: #0d6efd;
    font-weight: bold;
    margin-top: 2rem;
  }
  & p{
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
  }
  & li{
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
  }
}

.product-page {
  background-color: #fff;
  box-shadow: 2px 2px 50px #dfdfdf;
  padding: 80px;
}

.product-page-heading {
  text-align: center;
  background-color: #0d6efd;
  color: #fff;
  padding: 10px;
  margin: 80px 0 40px 0;
}
  

.product-questions {
  background: #fff;
  border: none;
  .accordion-button:not(.collapsed) {
    color: #0d6efd;
    background-color: #f9f9f9;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  }
}


.product-image{
  text-align: center;
  img {
    margin-top: 40px;
    width: 400px;
  }
  @media screen and (max-width: 668px){
    img{
      width: 300px;
      margin-top: 0px;
    }
  } 
}


