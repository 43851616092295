.GoTabsReg {
    background-color: #0d6efd !important;
    cursor: pointer;
    color: #fff !important;
    margin-top: 0.4rem;
    padding: 0.8rem;
    border-radius: 0px !important;
  }

  .GoTabsReg-btn{
    padding: 0.7rem !important;
    text-align: left;
  }
  
  .GoTabsReg:hover {
    background-color: #1b1b1b !important;
  }

  .GoTabsRegbtn-in-line {
    background: none !important;
    border: none;
    color: #0d6efd;
    padding: 0px !important;
    &:hover{
      color: #141414;
    }

  } 
  
  .GoTabsReg .nav-link.active {
    background-color: #1b1b1b !important;
  }
  
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #1b1b1b !important;
  }
  

  // log in scss

  .login-box{
    margin: auto;
    padding: 2rem 1rem;
    box-shadow: 0px 2px 6px 4px #ebebeb;
    margin: 1rem 2rem;
    border-radius: 0.6rem;
    &:hover{
      box-shadow: 0px 2px 6px 6px #e5e5e5;
    }

    .login-box-form{
      padding: 1rem;
    }
  }




  // for order view by id page
  .orderCard {
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 10px 30px 0 rgb(0 60 179 / 5%);
    border: 0 solid #dfe7ff;

    .list-group {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        border-radius: 5px;
        
        .list-group-item {
            position: relative;
            display: block;
            // padding: 0.75rem 1.25rem;
            background-color: #fff;
            // border: 1px solid rgba(0,0,0,.125);
            
            .order-info-responsive {
                display: block;
                width: 100%;
                // overflow-x: hidden;
                // -webkit-overflow-scrolling: touch;
            }
        }
    }
}

.product-key-box {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 2px;
}


.account-detail-form {
  background-color: #fff;
  box-shadow: 2px 2px 10px #eaeaea;
  padding: 2rem;
  margin-top: 1rem;
}

.gosoftwar-hr{
  color: rgb(155, 155, 155);
}

.login-error {
  text-align: center;
  background-color: #ffd8d8;
  padding: 1rem;
  border-radius: 10px;
  margin: 0 2rem;
}

.icon-btn{
  cursor: pointer;
}