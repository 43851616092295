.sidebar{
    // height: calc(100vh - 66px);
    height: 100vh;
    // background-color: #000c17;
    position: fixed;
    top:  0px;
    z-index: 999;
    font-family: 'Montserrat', sans-serif;
}


.ant-layout {
    background: #fbfbfb !important;
}

.ant-layout-sider {
    background: #111827 !important;
    // background: #af883f !important;
    box-shadow: 1px 2px 10px #9b9a9a;
}
.ant-layout-header {
    background: none !important;
}
.ant-menu {
    background: #111827 !important;
}

.ant-layout-footer {
    background: #f9fbfe !important;
}


.sidebar-NX {
    width: 48px;
}


.sidebarWrapper {
    padding: 20px 0px;
    color: #212529;
    position: relative;
}

.sidebarWrapperButton{
  position: absolute;
  right: -60px;
  z-index: 999;
  top: 5px;
//   bottom: -40px;

  background: none;
  border: none;
  cursor: pointer;

  margin: auto -10px;
//   background-color: #0d6efd;
//   border: 2px solid #3c4045;
//   padding: 12px;
  border-radius: 0px 20px 20px 20px;
  height: 55px;
  width: 55px;
}

.sidebarWrapperButton span{
    display: block;
    margin-top: 4px;
    background-color: rgb(37, 34, 34);
    border-radius: 15px;
    height: 4px;
    width: 26px;

    transition: 0.4s;
}

.sidebarMenu {
    margin-bottom: 6px;
}

.sidebarTitle{
    font-size: 14px;
    font-weight: 500;
    color: rgb(245, 244, 244);
    background-color: #0d3d84;
    padding: 10px;
    display: flex;
    align-items: center;
}

.sidebarList{
    list-style: none;
    /* padding: 5px 0px 5px 20px; */
}

.sidebarListItem{
    padding: 5px;
    cursor: pointer;
   
}

.sidebarListItem-NX{
    margin: auto;
}

.sidebarListItem a{
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    color: #ffffff;
    display: flex;
    align-items: center;
}

.sidebarListItem.active, .sidebarListItem:hover{
    background-color: rgb(13, 61, 132);
}


.sidebarLogo {
    margin-bottom: 0.6rem;
    margin-top: 1rem;
    padding: 1rem 1rem 1.6rem 1rem;
    text-align: center;
    border-bottom: 1px solid #1a243b;
}


// topbar scss
.topbar {
    width: 100%;
    height: 66px;
    background-color: #ffffffe6;
    position: sticky;
    top: 0;
    z-index: 9;
    box-shadow: 0 0 10px rgba(27, 27, 27, 0.08);
}

.topbarWrapper {
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .topRight {
        display: flex;
        align-items: center;
        .topbar-text{
            margin-right: 1rem;
            margin-top: 0.8rem;
        }
    }
}

.admin-logo img{
  width: 200px;
}

.admin-avatar{
    color: rgba(0, 0, 0, 0.85) !important;
    border-radius: 50%;
    display: flex;
    background: #e3e3e3 !important;
    width: 2.6rem !important;
    height: 2.6rem !important;
}


// for antd sidebar menu

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #29385a !important;
    // width: 200px;
    border-radius: 20px;
    // margin-left: 16px;
    box-shadow: 1px 1px 20px #1a253d;
}

vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0px !important;
}



// for admin page button

.admin-page-button {
    background-color: #28c76f !important;
    padding: 10px 20px;
    // padding: 4px 20px;
    width: auto;
    float: right;
    border-radius: 4px;
    border: 1px solid #28c76f;
    color: #ffffff !important;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.admin-page-button:hover {
    border: 1px solid #28c76f;
    color: #379231 !important;
    background-color: #ffffff !important;
    box-shadow: 0 10px 30px 0 rgb(182 182 182 / 27%);
}


//ant design header
.ant-layout-header {
    padding: 0 !important;
}

.ant-input{
    font-size: 15px !important;
    line-height: 1.9715 !important;
}
.ant-btn{
    line-height: 1.9715 !important;
    height: auto !important;
    font-size: 15px !important;
}
.ant-picker{
    line-height: 1.9715 !important;
    font-size: 15px !important;
    padding: 9px 10px !important;
}
.ant-select{
    font-size: 15px !important;
    line-height: 1.9715 !important;
    .ant-select-selection-placeholder{
        line-height: 38px !important;
    }
}
.ant-select-single .ant-select-selector .ant-select-selection-item{
    line-height: 38px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto !important;
}

.admin-topbar-btn {
    border: none !important;
    margin: 0.3rem 0;
}

@media screen and (max-width: 668px){
    
}