.admin-profile-page{
    // flex: 4;
    // background-color: #f7f7f7;
    // height: 100vh;
    
    .admin-profile-page-data{
        padding-top: 36px;
        font-family: 'Montserrat', sans-serif;
        // font-weight: bold;

        .admin-profile-page-title {
            // border-bottom: 1px solid #d5d5d5;
            padding: 0px 40px 8px 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        

        .admin-profile{
            padding: 10px 20px 8px 20px;
            
            .profile-box {
                background-color: #fff;
                padding: 2rem;
                box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
                border-radius: 0.8rem;
            }
            
        }
    }
}

