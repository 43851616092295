.shortPageLine {
    background-color: #f3f3f3;
    padding: 10px;
    margin-bottom: 40px;
    margin-top: 10px;
}
.sub-heading-product-category{
    text-align: center;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    background-color: #efefef;
    color: #000;
    font-family: "Ubuntu", sans-serif;
    h2{
        font-size: 2.3rem;
        font-weight: 600;
        margin-bottom: 0;
    }
}



//  category banner
.category_page_topbar{
    padding: 0px;
    margin-bottom: 1rem;
    .category_banner {
        position: relative;
        margin-bottom: 4rem;
        .category_banner_image{
            width: 100%;
            height: 270px;
        }
        .Banner_contant {
            background-color: #d9edf7;
            color: #31708f;
            padding: 1rem;
            font-family: "Ubuntu", sans-serif;
            width: fit-content;
            position: absolute;
            bottom: -56px;
            left: 320px;
            & h6{
                color: #31708f;
            }
        }
    }
}

@media only screen and (max-width: 668px){
    .category_banner {
        margin-bottom: 8rem !important;
        .Banner_contant{
            bottom: -147px !important;
            left: 0px !important;
        }
        .category_banner_image{
            height: auto !important;
        }
    }
}