html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif !important;
  // font-family: 'Ubuntu', sans-serif !important;
  scroll-behavior: smooth;
}

code {
  font-family: 'Montserrat', sans-serif !important;
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1200px !important;
  }
}


.works-homepage-img {
  vertical-align: middle;
  max-width: 100%;
}

svg{
  vertical-align: middle;
  max-width: 100%;
}


.gosoftware-padding{
  padding: 40px 0px;
}



/* Product page css */
.goSoftbtn {
  background-color: #0d6efd !important;
  font-size: 1.2rem !important;
  color: #fff !important;
  text-decoration: none !important;
  padding: 10px 32px !important;
  border-radius: 0px !important;
}

.goSoftbtn:hover{
  background-color: rgb(27, 26, 26) !important;
  color: #fff;
}

// toast css
.toast {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 20%) !important;
  background-color: #03a9f4 !important;
  color: #fff;
  font-size: 1rem !important;
  .toast-header{
    background-color: rgb(91, 161, 232) !important;
    color: rgb(255, 255, 255) !important;
    border: 0.1rem solid #1550a5 !important;
    }
  }


  // for form input box
  .form-control:hover {
    border: 1px solid #000;
}



/* ProdReviews css */
.overAll {
  background-color: #1da720;
  color: #fff;
  text-align: center;
  padding-top: 10px;

  .number {
    h2 {
      color: #fff;
      margin-bottom: 0.4rem;
    }
  }
  
  .outOf {
    background-color: #000;
    padding: 8px 0px 2px 0px;

    h5 {
        color: #fff;
    }
  }
}


.error-message {
  background-color: #fccaca;
  color: #000;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 20px;
  border-radius: 4px;
}
.error-message-success {
  background-color: #bfe9ae;
  color: #000;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 20px;
  border-radius: 4px;
}


// for alice-slider custom css
.alice-carousel__stage-item{
  width: 298px !important;
}
ul.alice-carousel__dots {
  display: none;
}

@media only screen and (max-width: 668px) {
  .alice-carousel__stage-item{
    width: 362px !important;
  }
  ul.alice-carousel__dots {
    display: block;
  }
}

/* Microsoft partner section css */  
.microPart{
  color: #3c763d;
  background-color: #dff0d8;
  padding: 20px;
  margin-top: 10px;
  border-left: 5px solid #cae6be;
  font-family: 'Ubuntu', sans-serif;
  h6{
    color: #3c763d;
    font-weight: 600;
    font-size: 1.1rem;
  }
}  


// for font smoothing
.smoothed {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {

  font-weight: 600 !important;
  font-family: "Trebuchet MS", Sans-serif;
}
.h2, h2 {
  font-size: 31px;
}


// for category bottom 
.category_bottom{
  margin-bottom: 2rem;
  & li{
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(111, 111, 111);
    font-family: "Ubuntu", sans-serif;
  }
}
.category_bottom_divider {
  background-color: #0d6efd;
  padding: 0.9rem;
  .category_bottom_divider_child{
    display: flex;
    justify-content: space-between;
    & h4{
      color: #fff;
      font-style: italic;
      line-height: 1.5rem;
    }
  }
}

.category_bottom_icons {
  margin: 4rem 0rem !important;
  text-align: center;
  & h4{
    margin-top: 1.6rem;
  }
  & p{
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(111, 111, 111);
    font-family: "Ubuntu", sans-serif;
  }
}
.category_bottom_information{
  margin-bottom: 4rem;
  & h3{
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  & p{
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(111, 111, 111);
    font-family: "Ubuntu", sans-serif;
  }
}


// placed order page css
.placed_order_text {
  text-align: center;
  background-color: #fff;
  // border: 2px solid rgb(232, 232, 232);
  box-shadow: 2px 2px 12px rgb(228, 228, 228);
  border-radius: 4px;
  width: fit-content;
  margin: auto;
  padding: 2rem 2rem;

  & h4 {
    margin-top: 1rem;
    font-size: 2rem;
    color: #1dc250;
    font-weight: 600;
  }

  & h6 {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .placed_order_page_btn {
    background-color: #0d6efd;
    color: #fff;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    border-radius: 0px;

    &:hover {
      background-color: #0a59d0;
      box-shadow: 1px 2px 6px rgb(154 154 154);
    }
  }
}

// for no data found component
.no_data_found{
  text-align: center;
    margin-top: 6rem !important;
    margin-bottom: 4rem !important; 
    & h4 {
      margin-top: 0.6rem;
      color: rgb(135, 135, 135);
    }
}