.admin-sales-page{
    // flex: 4;
    // background-color: #f7f7f7;
    
    .admin-sales-page-data{
        padding-top: 36px;
        font-family: 'Montserrat', sans-serif;
        // font-weight: bold;

        .admin-sales-page-title {
            // border-bottom: 1px solid #d5d5d5;
            padding: 0px 40px 8px 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
        .admin-sales{
            padding: 10px 20px 8px 20px;
            // margin: 20px;
            // border: 1px solid #d5d5d5;
            // border-radius: 6px;

            .ant-table-admin {
                background-color: #fff;
                box-shadow: 0 10px 30px 0 rgb(182 182 182 / 27%);
                // box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
                padding: 1rem;
                border-radius: 10px;
                &:hover{
                    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
                }

                .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
                .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
                .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
                .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
                    background: unset; //Change the existing color to `unset`
                }

                .ant-table-thead > tr > th {
                    background: #fff !important;
                    font-weight: 700 !important;
                }

            }

            .orderCard {
                background-color: #fff;
                border-radius: 14px;
                box-shadow: 0 10px 30px 0 rgb(0 60 179 / 5%);
                border: 0 solid #dfe7ff;

                .list-group {
                    display: flex;
                    flex-direction: column;
                    padding-left: 0;
                    margin-bottom: 0;
                    border-radius: 5px;
                    
                    .list-group-item {
                        position: relative;
                        display: block;
                        // padding: 0.75rem 1.25rem;
                        background-color: #fff;
                        // border: 1px solid rgba(0,0,0,.125);
                        
                        .order-info-responsive {
                            display: block;
                            width: 100%;
                            // overflow-x: hidden;
                            // -webkit-overflow-scrolling: touch;
                        }
                    }
                }
            }
        }
    }
}

.product-key-box {
    background-color: #f5f5f5;
    padding: 1rem 1rem 0.3rem 1rem;
    border-radius: 2px;
}


.subFormButton{
    background-color: #0d3d84;
}


// for qty
.order-page-keyqty {
    border: 1px solid rgb(213, 213, 213);
    box-shadow: rgb(229 229 229) 1px 1px 8px;
    float: left;
    margin-right: 18px;
    align-items: center;
}
.send_key_btn {
    height: 38px;
    &:hover{
        box-shadow: 0px 6px 16px 2px #d0d0d0;
    }
}



