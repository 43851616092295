.contact-information {
    background-color: #ffffff;
    padding: 1.2rem 0.6rem 0.8rem 0.6rem;
    margin-bottom: 1rem;
    box-shadow: 1px 2px 8px #ebebeb;
    border: 1px solid rgb(231 231 231);
    text-align: center;
    height: 90%;
    border-radius: 6px;
    &:hover{
        border: 1px solid #0d6efd;
    }
}


.ContactBg {
    padding: 60px 40px;
    background-color: #fff;
    box-shadow: 2px 2px 40px #e0dfdf;
    margin-top: 60px;
    margin-bottom: 80px;
    border-radius: 4px;
}