.topbar-gosoftware {
  background-color: #4707C8;
  padding: 0.1rem 1.2rem;
  height: 40px;
  .nav-link-topbar {
    padding: 0;
    margin-left: 0.6rem;
  }
}

.topbar-second-child{
  float: right;
}

@media only screen and (max-width: 768px){
  .topbar-gosoftware{
    height: auto;
    padding-bottom: 0.6rem;
    text-align: center;
  }
  .topbar-second-child{
    float: none;
    display: flex;
    justify-content: center;
  }
}

.navbar-collapse {
    flex-grow: 0 !important;
}

.navbar{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    z-index: 999;
  }

.Mymenu{
    display: block;
    padding: 0rem 0.4rem;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    font-family: 'Montserrat', sans-serif;
  }
// .Mymenu:hover {
//   transform: scale(1.3);
// }


.Mymenutop{
  display: block;
  padding: 0rem 0.2rem;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  font-family: 'Montserrat', sans-serif;

  .header-top-button{
      background: none !important;
      color: #fff;
      padding: 0px;
      border-radius: 0;
      border: none;
     &:hover {
      color: #fff;
     }
   }
}

.top-bor-style{
    margin-top: 0.3rem;
    margin-bottom: 0;
  font-family: "Montserrat", Sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
}

.drop-Menu{
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

.drop-Menu .badge {
  float: right;
}

.dropdown-menu {
  border-radius: 0px !important;
  padding: 0px !important;
  background-color: #fff;
  border: 0px !important;
  box-shadow: 0px 0px 10px 0px #d0cfcf6e;
}

.Bgnav{
    background-color: #165483 ;
  }
  
  
  .header-form{
    border-radius: 30px 0px 0px 30px !important;
    padding: 12px 24px !important;
  }
  
  .header-form:focus {
    box-shadow: none !important;
    border-color: #212529 !important;
  }

  .header-form-btn{
    border-radius: 0px !important;
  }

  .header-form-btn:focus {
    box-shadow: none !important;
    border-color: #212529 !important;
  }
  
  
  
  .header-form-control {
    border-radius: 0rem !important;
    border: 1px solid #ced4da !important;
  }
  
  .header-form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #212529 !important;
    outline: 0;
    box-shadow: none !important;
  }


  .gosoftware-textLight {
    color: #090600 !important;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    margin-right: 0.8rem;
    font-size: 1rem;
    transition: 0.3s;
    &:hover{
      transform: scale(1.2);
      color: #0d6efd !important;
    }
    
     .dropdown-toggle {
      color: #090600 !important;
      &:hover{
        color: #0d6efd !important;
      }
    }
    
    & a{
      color: #454545;
      font-weight: 600;
    } 
   
    .dropdown-item {
      border-bottom: 1px solid #eeeeee;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      color: #666666;
      font-size: 0.8rem !important;
      .dropdown-item:hover {
        background-color: #fff !important;
      }
    }
  }

  .dropdown-item:focus, .dropdown-item:hover {
    color: #0d6efd;
    background-color: #fff !important;
}

  .myTextLight {
    color: #fff !important;
  }

  .header-cart {
    background: #0d6efd;
    color: #fff;
    border: 1px solid #dfdfdf;
    width: 11rem;
    background-size: 300% 100%;
    text-align: center;
    padding: 0.5rem 1rem;
    height: 2.5rem;
    margin-left: 1rem;
    cursor: pointer;
    span{
      color:#fff;
      margin-right: 0.2rem;
      float: left;
    }
    
    &:hover {
      background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    }
}

  @media only screen and (max-width: 668px){
    .header-cart{
      margin: auto;
    }
    .header-search-bar{
      margin: auto;
    }
    .gosoftware-textLight:hover{
        transform: none;
      }
  }

.header-search-bar {
  width: 15rem;
}

// for cart icon
.header-cart-icon {
  position: relative;
  width: 22px;
  height: 22px;
  float: left;
  .header-cart-icon-child {
    position: absolute;
    right: -16px;
    bottom: 8px;
  }
}
  


  // footer css
.footer-heading{
  font-family: 'Ubuntu', sans-serif;
  color: #fff;
}

.text-light-footer{
  font-size: 1rem;
  font-weight: 500;
  color: #dcdbdb !important;
  font-family: 'Ubuntu', sans-serif;
}

.footertext{
  color: #dcdbdb !important;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;
}

.footerLink {
  text-decoration: none;
  color: #dcdbdb !important;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;
  transition: 0.3s;
  &:hover {
    color: #b1d0ff !important;
    margin-left: 4px;
  }
}
  
  