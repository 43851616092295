
/* slider section css */
.slide{
    position: relative;
    .slide-img{
      height: 320px;
      @media only screen and (max-width: 768px) {
        height: auto;
      }
    }
    .banner-button{
      margin-top: 0.1rem;
      padding: 0.6rem 2rem;
      font-size: 1.2rem;
      background-color: rgb(11, 11, 11);
    }
  }
  
  .carousel-caption {
    text-align: left !important;
    bottom: 0 !important;
    right: 1%;
    left: 8%;
    top: 0;
  }
  
  @media only screen and (max-width: 768px){
    .carousel-caption {
      text-align: left !important;
      padding-top: 1rem !important;
      padding-bottom: 1.4rem !important;
      bottom: -0.75rem !important;
    }
    .carousel-caption h2 {
      font-size: 0.9rem !important;
      color: #ffffff !important;
    }
    .banner-button{
      margin-top: 0 !important;
      padding: 0.1rem 0.3rem !important;
      font-size: 0.4rem !important;
      background-color: rgb(32, 32, 32);
    }
  }


  .slide-topbar {
    background-color: #EAF1FB;
    padding-top: 0.4rem;
    padding-bottom: 0rem;
    text-align: center;
    .slide-topbar-data{
      display: flex;
      justify-content: space-between;
    }
}



/* Home page pro slider css */

.alice-carousel__prev-btn, .alice-carousel__next-btn-item {
    display: none !important;
  }
.alice-carousel__next-btn {
  display: none !important;
}  


/* Work page css */  

.myCircle{
    height: 50px;
    width: 50px;
    background-color: #e5e5e5;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding: 10px;
    color: #0d6efd;
    font-weight: bold;
  }
.works-heading{
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.9rem;
  font-weight: 600;
}



  .slider-top-bar{
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Ubuntu', sans-serif;
  }

  .slider_caption_text{
    color: #fff;
    font-size: 3rem;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
  }

  .home-page-heading{
    text-align: center;
    font-family: Ubuntu, sans-serif;
    font-weight: 600;
    font-size: 2.3rem;
  }

  @media only screen and (max-width: 668px){
    .home-page-heading{
      font-weight: 600;
      font-size: 1.6rem;
    }
    .footer-heading {
      margin-top: 1rem;
    }
    .alice-carousel__prev-btn, .alice-carousel__next-btn-item {
      display: block;
    }
  }