.myCard{
    position: relative;
    cursor: pointer;
    min-height: 92%;
    margin-bottom: 46px;
  }

  .product-offer-per{
    position: absolute;
    background-color: #d34e1e;
    color: #fff;
    font-size: 0.9rem;
    line-height: 1.3rem;
    padding: 0.6rem 0.5rem;
    border-radius: 4px;
    top: 14px;
  }

.myCard .card-title {
  color: #32373c;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.2rem;
  line-height: 1.8rem;
}  

  .myCard:hover {
    box-shadow: 7px 8px 10px 0 rgb(0 0 0 / 10%);
    // .cardImg{
    //   transform: scale(1.1);
    // }
  }

  .gosoftware-cards-text{
    line-height: 2rem;
    .gosoftware-cards-text-mrp{
      color: #8ab8fd !important;
    }
    .gosoftware-cards-text-sale-price{
      font-size: 1.4rem;
      font-weight: bold;
      color: #0d6efd !important;
    }
  }
  

  .buynow-btn {
    padding: 0.7rem 1.6rem !important;
    border-radius: 0;
    background-color: #1F7EE6;
    border: none;
    background-size: 300% 100%;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    &:hover {
       background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
    }
}

.card-footer {
  background-color: #fff;
  border-top: none;
}

.cardImg {
  width: 270px;
  height: 270px;
  margin: auto;
  transition: transform .2s;
  margin-top: 16px;
}

.shop_page_heading{
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  padding-bottom: 4rem;
  color: rgb(32, 32, 32);
  padding-top: 2rem;
}