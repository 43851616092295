
#checkout {
    background: #eff3f9 !important;
    min-height: 100vh;
  p, h1, h2, h3, h4, h5, h6, label{
    font-family: "Inter", sans-serif !important;
  }
  #checkout_box{
    max-width: 1000px;
    margin: 0 auto;
  }

  .subHeader {
    padding: 50px 0px;
    position: relative;
    text-align: center;
    display: flex;
  }

  .Back-to-Cart {
    text-decoration: none;
    color: #858484;
    font-size: 14px;
    position: absolute;
    left: 0;
    margin: 10px 0;
  }

  .Back-to-Cart:hover {
    color: #858484;
  }

  .sec-badge {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAk1BMVEUAAAAfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxIfkxJh1j6XAAAAMHRSTlMA+uDb6wL28JIH5cGucS8Ul4J9KyQMu7SnnRrWWiDHOhDRzIZ2bFFEQWdhSTQniInoPmQmAAACtUlEQVRIx+2W2ZqiMBCF2VEBWwQBgXZD3LXr/Z9uSjsQUgHEuZqLOTd+VPJbSyoFyr+pu+8Hf0d+XSJARef0U3KcuxYwqXG++AA9jSYVCC8Zs+MwMvB1Rm7nYeqt2IP2mL4jU89hm/Vi/WuazjNmWu2/exI9JFWYybW5cFwajHcP7emv60SxQPL/unX6awk9V7k5XkdsaRmxLTuPuGVVKaaDankVzB49j/5T9AXbAyBbDOyfHcBMsCwB3JZ9i8VYtiZ0rwuQ0ABdzbBtQ3MxQeooEgwRwFLccoFapLgj7D3BsKV5BNCQeLIFgC4YNAq7yIzKc+mNVBqlDzARDDZuFQxZXYQZgCYszQFswWC1wPOqBWTYGgrv33qeUFhvep6YJGeDOppReM+uDK3Pg4bi0HPW0LP5UomOxvScezpsE2sqJqZNUJoN6Dr6EjrMoS0XC3FQ7czuVvaboYxVkMVdO7S4Z4xyXEdtMUDdz21g+ubloN1+xOV7/a6wGHvC2alSOMWHgwCH3MJh4+WHwid8mIrXHsPz2zzfKs9hY2JZC4UedFzDKtSudaBwgqVXRBW4lXoGVVEMCdZYsUnFphQ2cCuHaXW4xgj4LbBOj2qPAfGU+RTTpXO+Hi0KbwFW+CMPvFtfh/HZVuKvHPeS30cqw6yHEmwUSTP+9zeJ1tgAXajsVhDdoTEQ0vAeBNOXguAeptWd+mHJSYpxIVV6tVFZuSR9A7nlsmLBMZ0IcO5jDyDMDOl2QNDNphi0uulavSJsdK6azzPIRRsZjKB3veRXQEYslfOk09ZC74BUWtZzi32S7esJLmRmP2w6gPoxibUA1O79V0sCKF0oTK4BKur1y28sKqs+NMP5b6f7yiCt2degU+R54bAv4KMyVBedXKpS+UR5zNHoYiofKizdzLb12AuU/+L6A4H/lrx1o2W8AAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-size: 42px;
    text-align: left;
    margin: auto;
    width: auto;
    padding-left: 45px;
    padding-top: 2px
  }

  .pay_title_number {
    border: 1px solid #000;
    border-radius: 50%;
    padding: 0rem 0.3rem;
    font-size: 1.2rem;
    margin-right: 10px;
  }

  .cart_total_checkout {
    background-color: #fff;
    border: 5px solid #f7f7f7;
    border-radius: 10px;
    width: 30%;
    border: 1px solid #cbd5e0;
    box-shadow: 0 0 0 3px rgb(0 0 0 / 5%);
  }

  .cart_total_checkout_heading {
    border-bottom: 1px solid rgb(236, 236, 236);
    padding: 10px 14px 8px;
    font-size: 14px;
  }

  .cart_total_checkout_product {
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 0.6rem 1rem 1rem 0.4rem;
  }

  .cart_total_checkout_coupon {
    padding: 1rem;
  }

  .sec-text {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1rem;
  }

  @media only screen and (max-width: 668px) {
    .sec-badge {
      margin: 50px 0px;
    }
  }

  .cre-stripe {
    background-color: #f7f7f7;
    padding: 8px 12px;
    border: 2px solid #2f2f2f;
    border-radius: 4px;
    box-shadow: 2px 2px 12px #cfcfcf;
    position: relative;
    cursor: pointer;
  }

  .cre-stripe::before {
    content: "";
    border-left: 10px solid #2f2f2f;
    border-bottom: 7px solid transparent;
    border-top: 7px solid transparent;
    right: 0;
    left: unset;
    margin-bottom: unset;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    margin-right: -12px;
  }

  .secure-card {
    background-color: #f7f7f7 !important;
    border: 2px solid #2f2f2f !important;
  }

  .secure-info {
    position: relative;
    font-size: 14px;
    padding: 0px 0px 18px 48px;

  }

  .secure-info::before {
    content: "";
    background-image: url(../../Images/secure-icon-30.png);
    background-repeat: no-repeat;
    width: 42px;
    height: 42px;
    position: absolute;
    border: 0;
    left: 0;
  }


  .cart-checkout-page-btn {
    // padding: 0.1rem 0.9rem !important;
    border-radius: 4px !important;
    font-size: 1rem !important;
    height: auto !important;
    background-color: #0d6efd !important;
    color: #fff !important;
  }

  .checkout-input-btn-dcr {
    height: 32px;
    width: 20px;
    background: rgb(255, 255, 255);
    border-width: 1px 0px 1px 1px;
    border-color: #dadada;
    border-style: solid;
  }

  .checkout-input-btn-inc {
    height: 32px;
    width: 22px;
    background: rgb(255, 255, 255);
    border-width: 1px 1px 1px 0px;
    border-color: #dadada;
    border-style: solid;
  }

  .checkout-input-box {
    padding: 2px 0px;
    height: 32px;
    width: 26px;
    text-align: center;
    border-radius: 0;
    border-width: 1px 0 1px 0;
    border-color: #dadada;
    border-style: solid;

    &:hover {

      border-width: 1px 0 1px 0;
      border-color: #dadada;
      border-style: solid;
    }
  }


  .user-data {
    background-color: #e8f0fe;
    padding: 1.2rem;
    border: 1px solid #ced4da;
    border-radius: 4px;

    &:hover {
      border: 1px solid #202020;
    }
  }


  // increment derement
  .cart-checkout-IncrDecrStyle {
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 2px;
    // height: 28px;
    width: auto;
  }


  .checkout-subTotal {
    padding: 0.6rem 1rem;
    border-bottom: 1px solid #e3e3e3;

    h6 {
      margin-bottom: 0;
    }
  }


  .emailSubmit-btn {
    background-color: #1a202c;
    padding: 0.8rem !important;
    font-size: 1rem;

    &:hover {
      background-color: #1a202c;
    }
  }

  .emailEdit {
    position: absolute;
    top: 20px;
    right: 26px;
    cursor: pointer;
  }

  .form_checkout_btn {
    padding: 0.6rem !important;
  }


  .checkout_topbar {
    background-color: #fff;
    text-align: center;
    box-shadow: 0 1px 10px rgb(26 32 44 / 10%);
    padding: 8px 0px 10px 0px;

    .checkout_topbar_text {
      color: rgb(33, 33, 33);

      & span {
        font-size: 1rem;
        margin-left: 0.6rem;
        font-weight: 600;
      }
    }
  }



  // for no data found
  .checkout_nodata_found {
    text-align: center;
    margin-top: 9rem;
    & h4 {
      margin-top: 0.6rem;
      color: rgb(126, 126, 126);
    }
  }
  .bg_grey{
    background: #f7fafc;
  }
  .font-weight-normal{
    font-weight: normal;
  }
}
