$bor-color : #d5d5d5;

.admin-category-page{
    // flex: 4;
    // background-color: #f7f7f7;
    
    .admin-category-page-data {
        padding-top: 36px;
        font-family: 'Montserrat', sans-serif;
        // font-weight: bold;
   
        .admin-category-page-title{
            // border-bottom: 1px solid $bor-color;
            padding: 0px 40px 8px 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .admin-category{
            padding: 10px 20px 8px 20px;
            // margin: 20px;
            // border: 1px solid $bor-color;
            // border-radius: 6px;

            .ant-table-admin {
                background-color: #fff;
                box-shadow: 0 10px 30px 0 rgb(182 182 182 / 27%);
                // box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
                padding: 1rem;
                border-radius: 10px;
                &:hover{
                    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
                }

                .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
                .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
                .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
                .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
                    background: unset; //Change the existing color to `unset`
                }

                .ant-table-thead > tr > th {
                    background: #fff !important;
                    font-weight: 700 !important;
                }

            }
            
            .category-image-button {
                background-color: #fafafa;
                padding: 0.5rem 1rem;
                color: #272727;
                margin-top: 1rem;
                border: 2px dashed #eee;
                font-weight: 600;
            }
        }
    }
}

.category-image-button{
    padding: 3px 14px;
}


.subFormButton{
    background-color: #0d3d84;
}




